.Modal-Functions{
    width: 99.9%;
    position: fixed;
    bottom:0px;
    min-height: 200px;
    z-index:10;
    padding:10px;
    background-color: #1e1e1e;
    border-top: solid 13px #32323b;
    border-left: solid 2px #32323b;
    border-right: solid 2px #32323b;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    max-height: 80vh;
    overflow-y: auto;
}