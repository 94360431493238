.loadContainer{
    display: fixed;
    width:100vw;
    height:100vh;
    top:0;
    left:0;
    justify-content: center;
    align-items: center;
}
.loadContainer-img{
    display:flex;
    width:100%;
    height:100%;
    justify-content: center;
    align-items: center;
}