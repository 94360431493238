.jm-navbar{
    width:100%;
    padding:0;
    background: linear-gradient(
    90deg,
    rgba(229, 0, 0, 0.9) 0%,
    rgba(229, 0, 0, 0.9) 100%);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.jm-divisor{
    display:flex;
    flex-direction: row;
}
.jm-contenerdor{
    width: 33%;
    text-align: center;
    padding: 5px;
    cursor:pointer;
}
.jm-text{
    color:#fff;
    font-weight: bold;
    font-size: 20px;
}